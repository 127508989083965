const main = {
    en: {
        "main.choices.casino.title": "$500 FREE BONUS + 100% UP TO $10,000 on your first deposit",
        "main.choices.casino.button.signup": "Signup in the casino",
        "main.choices.casino.button.login": "Login in the casino",
        "main.choices.sport.title": "100% BONUS \n UP TO $3,000",
        "main.choices.sport.button.signup": "Signup in sports",
        "main.choices.sport.button.login": "Login in sports",

        "main.vip.title": "Live the best\nVIP experience at\nWinpot VIP",
        "main.vip.subtitle": "Be one of our VIP member",

        "main.slider.title": "Discover more advantages",
        "main.slider.slide.button": "Load More",

        "main.seo.title": "START WINNING TODAY WITH WINPOT CASINO ONLINE",
        "main.seo.paragraph_1": "Welcome to Winpot Casino! No matter what type of player you are, here you will find everything you need to have an enjoyable and rewarding time. From the latest and greatest video slots to traditional casino card and table games and live dealer games, we have it all. Along with that, you can enjoy regular promotions, friendly customer service, and much more. We have no doubt that all players, from beginners to those with years of experience, will have a fantastic time exploring everything we have to offer and we look forward to celebrating your first victories soon.",
        
        "main.seo.heading_2": "Spin the slot machine",
        "main.seo.paragraph_2_1": "If you love the sights and sounds of slot machines, you are going to love playing here at Winpot. We have a huge collection of games for you, from traditional classic slots to more modern video slots. Our games come from the best developers in the industry and we're sure you'll find plenty to enjoy.",
        "main.seo.paragraph_2_2": "Some players enjoy truly traditional games. These tend to be slot machines that have 3 reels and only a few paylines. They often have very simple themes, such as fruits, and very few bonus features. However, this does not mean that they do not offer the opportunity for great rewards. Even the most basic slot machines have huge prizes, so while the games may be simple, it doesn't mean the winnings are.",
        "main.seo.paragraph_2_3": "Of course, many people love the excitement of video slots and our offer includes many of them. Video slots typically have at least five reels and can sometimes have thousands of paylines. Slots have an incredible variety of themes, including nature, history, sports, myths and legends, fantasy, adventure and much more. You can also find branded slots that are based on movies, TV shows, bands, and other celebrities. Video slots are usually packed with bonus features such as free spins, pick games, expanding symbols, payout multipliers, and more. These features not only bring the themes to life but also give you the opportunity to earn even more. Some of our slots offer jackpot prizes that could really change your life.",
        "main.seo.paragraph_2_4": "As mentioned, we offer games from many of the industry's top developers, such as Pragmatic Play, Red Rake Gaming, Blueprint Gaming, and Pariplay. Almost every week, these developers release new titles, meaning there will almost always be something new for you to enjoy. If you're a fan of slots then be sure to take the time to explore the collection regularly as we're sure you'll find plenty of new favourites.",

        "main.seo.heading_3": "Try your luck at card games",
        "main.seo.paragraph_3_1": "While slot machines may have become the game they most associate with casinos, for many people, card games will always be what truly defines a casino. There are many popular casino card games such as blackjack and baccarat and here at Winpot you can find them all.",
        "main.seo.paragraph_3_2": "In fact, blackjack is the most popular casino card game in the world. There are many reasons for this; It's very easy to learn, can provide hours of fun, is really exciting and has a very low house edge meaning there is a real chance of winning. Over the years, several versions of blackjack have emerged, each with slightly different versions of rules and here we try to bring you as many of them as possible. This means that even the most demanding blackjack player will undoubtedly find the perfect game.",
        "main.seo.paragraph_3_3": "For years, baccarat was considered a game only aimed at the gaming elite. However, today, this is simply not true. Today, everyone is welcome to play baccarat, no matter how little or how much they want to bet. Baccarat is one of the most elegant casino games and those who have not tried it yet can be sure that they will love the experience.",
        "main.seo.paragraph_3_4": "You can enjoy these card games and many more here at Winpot. We also offer live dealer versions, providing a more realistic experience than ever. You can sit in front of a professional dealer and enjoy the feeling of playing in one of the best casinos in the world.",

        "main.seo.heading_4": "Take a seat at the games table",
        "main.seo.paragraph_4": "If you have ever been to one of the best casinos, you will know how exciting the atmosphere is around the gaming tables. You can enjoy this same atmosphere here at Winpot. Board game fans will find everything they could need. For example, when it comes to roulette, which is undoubtedly the most popular table game, we offer several versions. There are games to suit all budgets, with features like special betting areas and the ability to save favorite bets. Additionally, we also offer live dealer roulette, which gives you the opportunity to play with a real wheel and a professional dealer.",

        "main.seo.heading_5": "There is much more in our casino",
        "main.seo.paragraph_5": "Our casino is growing all the time, as we do our best to find and bring you the best games available. You can find all the news in the 'New' tab and usually at least one new game is added every week. We can't tell you in advance what the new games will be, it could be a slot with exciting new features, a new version of a game like blackjack or something completely different. Therefore, it is always worth taking some time to explore the games. More than likely, you'll find something that appeals to you and, with any luck, will lead you directly to your next big win.",

        "main.seo.heading_6": "Don't miss our casino promotions",
        "main.seo.paragraph_6_1": "There are plenty of places to gamble online, but you'll be hard-pressed to find an online casino with as many generous promotions as we offer here at Winpot. From the moment you create your account, you will receive bonuses.",
        "main.seo.paragraph_6_2": "There are many types of bonuses and there is always one for each type of player. For example, you may be offered a deposit bonus to ensure that you have plenty of funds in your account to play with. On some of our best slots we may offer you free spins, a refund of your previous week's losses or something else.",
        "main.seo.paragraph_6_3": "We always work to offer you the best casino promotions so you can have fun like never before. It is impossible to predict exactly what our next offer will be; What we do promise is that there will always be something you wanted to come. Be sure to check back often so you don't miss any of our casino promotions.",

        "main.seo.heading_7": "Let the fun continue with responsible gaming",
        "main.seo.paragraph_7": "The game is super fun, but it is important to always play responsibly. We want to do everything we can to make sure you have the best possible experience at our casino and that includes offering you a number of responsible gaming tools. We have tools to help you identify if you may be an at-risk gambler and we can help you find support if you need it. The vast majority of people enjoy casino games responsibly and without worry, but we would be remiss in our duty to you if we were not aware that, for a few, gambling may no longer be a game.",

        "main.seo.heading_8": "A place where playing is easy",
        "main.seo.paragraph_8_1": "At Winpot, we want to make your casino experience as seamless as possible. That's why we've made our website completely mobile-friendly. You can enjoy all of our casino games, including slots, card and table games, from your mobile device on the go. There is no need to install a special application. As long as you have an internet connection and a modern device, you can start playing in seconds. All you have to do is visit this site from your web browser, log in and you can start playing your favorite games right away.",
        "main.seo.paragraph_8_2": "This philosophy of ease extends to all areas of our service. If you need help, you'll find that customer service is very easy to contact. Likewise, we've worked hard to make banking as easy as possible. Why not discover for yourself all the fun that awaits you at Winpot? Create your account today and you can start playing right away.",
    },
    es: {
        "main.choices.casino.title": "$500 BONO GRATIS + 100% HASTA $10,000 en su primer depósito",
        "main.choices.casino.button.signup": "REGÍSTRATE EN EL CASINO",
        "main.choices.casino.button.login": "ACCEDER EN EL CASINO",
        "main.choices.sport.title": "100% EN BONO \n HASTA $3,000",
        "main.choices.sport.button.signup": "REGÍSTRATE EN EL DEPORTES",
        "main.choices.sport.button.login": "ACCEDER EN EL DEPORTES",

        "main.slider.title": "Descubre más ventajas",
        "main.slider.slide.button": "Leer Mas",

        "main.vip.title": "Vive la mejor\nexperiencia VIP en\nWinpot VIP",
        "main.vip.subtitle": "Sé uno de nuestros miembros VIP",

        "main.seo.title": "COMIENZA A GANAR HOY CON WINPOT CASINO ONLINE",
        "main.seo.paragraph_1": "¡Bienvenido al Casino Winpot! No importa qué tipo de jugador seas, aquí encontrarás todo lo que necesitas para pasar un rato agradable y gratificante. Desde las últimas y mejores tragamonedas de video hasta los juegos tradicionales de cartas y mesas de casino y los juegos de crupier en vivo, lo tenemos todo. Junto con eso, puedes disfrutar de promociones periódicas, un amable servicio de atención al cliente y mucho más. No tenemos ninguna duda de que todos los jugadores, desde los principiantes hasta aquellos con años de experiencia, pasarán momentos fantásticos explorando todo lo que tenemos para ofrecer y esperamos celebrar pronto tus primeras victorias.",
        
        "main.seo.heading_2": "Haz girar la máquina tragamonedas",
        "main.seo.paragraph_2_1": "Si te encantan las imágenes y los sonidos de las máquinas tragamonedas, te va a encantar jugar aquí en Winpot. Tenemos una enorme colección de juegos para ti, desde las tragamonedas clásicas tradicionales hasta las tragamonedas de video más modernas. Nuestros juegos provienen de los mejores desarrolladores de la industria y estamos seguros de que encontrarás mucho para disfrutar.",
        "main.seo.paragraph_2_2": "Algunos jugadores disfrutan de los juegos verdaderamente tradicionales. Estos tienden a ser máquinas tragamonedas que tienen 3 tambores y solo unas pocas líneas de pago. A menudo tienen temas muy simples, como frutas, y muy pocas características de bonificación. Sin embargo, esto no significa que no ofrezcan la oportunidad de grandes recompensas. Incluso las máquinas tragamonedas más básicas tienen premios enormes, por lo que, aunque los juegos pueden ser simples, no significa que las ganancias lo sean.",
        "main.seo.paragraph_2_3": "Por supuesto, a muchas personas les encanta la emoción de las tragamonedas de video y nuestra oferta incluye muchas de ellas. Por lo general, las tragamonedas de video tienen al menos cinco tambores y, a veces, pueden tener miles de líneas de pago. Las tragamonedas tienen una increíble variedad de temas, que incluyen naturaleza, historia, deportes, mitos y leyendas, fantasía, aventura y mucho más. También puedes encontrar tragamonedas de marca que se basan en películas, programas de televisión, bandas y otras celebridades. Por lo general, las tragamonedas de video están repletas de características de bonificación, como giros gratis, juegos de selección, símbolos de expansión, multiplicadores de pago y más. Estas características no solo dan vida a los temas, sino que también te dan la oportunidad de ganar aún más. Algunas de nuestras tragamonedas ofrecen premios de bote que realmente podrían cambiar tu vida.",
        "main.seo.paragraph_2_4": "Como se mencionó, ofrecemos juegos de muchos de los principales desarrolladores de la industria, como Pragmatic Play, Red Rake Gaming, Blueprint Gaming y Pariplay. Casi cada semana, estos desarrolladores lanzan nuevos títulos, lo que significa que casi siempre habrá algo nuevo para que disfrutes. Si eres fan de las tragamonedas, entonces asegúrate de tomarte el tiempo para explorar la colección de forma periódica, ya que estamos seguros de que encontrarás muchos nuevos favoritos.",

        "main.seo.heading_3": "Prueba tu suerte en los juegos de cartas",
        "main.seo.paragraph_3_1": "Si bien las máquinas tragamonedas pueden haberse convertido en el juego que más asocian con los casinos, para muchas personas, los juegos de cartas siempre serán los que realmente definen a un casino. Hay muchos juegos de cartas de casino populares, como el blackjack y el bacará y aquí, en Winpot, puedes encontrarlos todos.",
        "main.seo.paragraph_3_2": "De hecho, el blackjack es el juego de cartas de casino más popular del mundo. Hay muchas razones para esto; es muy fácil de aprender, puede proporcionar horas de diversión, es realmente emocionante y tiene una ventaja de la casa muy baja, lo que significa que hay una posibilidad real de ganar. A lo largo de los años, han surgido varias versiones de blackjack, cada una con versiones de reglas ligeramente diferentes y aquí intentamos traerte el mayor número posible de ellas. Esto significa que, sin duda, incluso el jugador más exigente de blackjack encontrará el juego perfecto.",
        "main.seo.paragraph_3_3": "Durante años se consideró que el bacará era un juego solo dirigido a la élite de los juegos. Sin embargo, hoy en día, esto simplemente no es cierto. Hoy, todos son bienvenidos a jugar al bacará, no importa cuán poco o mucho quieran apostar. El bacará es uno de los juegos de casino más elegantes y quienes aún no lo han probado pueden estar seguros de que les encantará la experiencia.",
        "main.seo.paragraph_3_4": "Puedes disfrutar de estos juegos de cartas y muchos más aquí, en Winpot. También ofrecemos versiones con crupier en vivo, que proporcionan una experiencia más realista que nunca. Puedes sentarte frente a un crupier profesional y disfrutar de la sensación de jugar en uno de los mejores casinos del mundo.",

        "main.seo.heading_4": "Toma asiento en la mesa de juegos",
        "main.seo.paragraph_4": "Si alguna vez has acudido a uno de los mejores casinos, sabrás lo emocionante que es la atmósfera alrededor de las mesas de juego. Puedes disfrutar de este mismo ambiente aquí, en Winpot. Los fans de los juegos de mesa encontrarán todo lo que podrían necesitar. Por ejemplo, cuando se trata de la ruleta, que sin duda es el juego de mesa más popular, ofrecemos varias versiones. Hay juegos para todos los presupuestos, con características como áreas de apuestas especiales y la capacidad de guardar apuestas favoritas. Además, también ofrecemos ruleta con crupier en vivo, que te da la oportunidad de jugar con una rueda real y un crupier profesional.",

        "main.seo.heading_5": "Hay mucho más en nuestro casino",
        "main.seo.paragraph_5": "Nuestro casino está creciendo todo el tiempo, a medida que hacemos nuestro mejor esfuerzo para encontrar y ofrecerte los mejores juegos disponibles. Puedes encontrar todas las novedades en la pestaña ‘Nuevo‘ y, por lo general, cada semana se agrega al menos un juego nuevo. No podemos decirte de antemano cuáles serán los nuevos juegos, puede ser una tragamonedas con nuevas y emocionantes características, una nueva versión de un juego como el blackjack o algo completamente diferente. Por lo tanto, siempre vale la pena tomarse un tiempo para explorar los juegos. Es más que probable que encuentres algo que te atraiga y, con un poco de suerte, te lleve directamente a tu próxima gran victoria.",

        "main.seo.heading_6": "No te pierdas nuestras promociones de casino",
        "main.seo.paragraph_6_1": "Hay muchos lugares para apostar en línea, pero te costará encontrar un casino online con tantas promociones generosas como ofrecemos aquí en Winpot. Desde el momento en que crees tu cuenta, recibirás bonificaciones.",
        "main.seo.paragraph_6_2": "Hay muchos tipos de bonos y siempre hay uno para cada tipo de jugador. Por ejemplo, es posible que se te ofrezca un bono de depósito para garantizar que tengas muchos fondos en tu cuenta para jugar. Es posible que en algunas de nuestras mejores tragamonedas te ofrezcamos giros gratis, un reembolso de las pérdidas de tu semana anterior o alguna otra cosa.",
        "main.seo.paragraph_6_3": "Siempre trabajamos para ofrecerte las mejores promociones de casino para que puedas divertirte como nunca antes. Es imposible predecir con exactitud cuál será nuestra próxima oferta; lo que sí prometemos es que siempre habrá algo que deseabas que llegara. Asegúrate de revisar a menudo para que no te pierdas ninguna de nuestras promociones del casino.",

        "main.seo.heading_7": "Que siga la diversión con el juego responsable",
        "main.seo.paragraph_7": "El juego es superdivertido, pero es importante jugar siempre de manera responsable. Queremos hacer todo lo posible para asegurarnos de que tengas la mejor experiencia posible en nuestro casino y eso incluye ofrecerte una serie de herramientas de juego responsable. Tenemos herramientas para ayudarte a identificar si puedes ser un jugador en riesgo y podemos ayudarte a encontrar apoyo, si lo necesitas. La gran mayoría de las personas disfrutan de los juegos de casino de manera responsable y sin necesidad de preocuparse, pero seríamos negligentes en el cumplimiento de nuestro deber para contigo si no fuéramos conscientes de que, para unos pocos, el juego puede dejar de ser un juego.",

        "main.seo.heading_8": "Un lugar donde jugar es fácil",
        "main.seo.paragraph_8_1": "En Winpot, queremos que tu experiencia en el casino sea lo más fluida posible. Es por eso que hemos hecho que nuestro sitio web sea completamente compatible con dispositivos móviles. Puedes disfrutar de todos nuestros juegos de casino, incluidas las tragamonedas y los juegos de cartas y de mesa, desde tu dispositivo móvil mientras viajas. No es necesario instalar una aplicación especial. Siempre que tengas una conexión a Internet y un dispositivo moderno, puedes comenzar a jugar en segundos. Todo lo que tienes que hacer es visitar este sitio desde tu navegador web, iniciar sesión y puedes empezar a jugar tus juegos favoritos de inmediato.",
        "main.seo.paragraph_8_2": "Esta filosofía de facilidad se extiende a todas las áreas de nuestro servicio. Si necesitas ayuda, descubrirás que es muy fácil estar en contacto con el servicio de atención al cliente. Del mismo modo, hemos trabajado arduamente para que la banca sea lo más fácil posible. ¿Por qué no descubrir por ti mismo toda la diversión que te espera en Winpot? Crea tu cuenta hoy y podrás empezar a jugar de inmediato.",
    },
};

export default main;
