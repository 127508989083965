import {getTrackingValues, setValueToBrowser} from "./trackingQueryHook";
import {initializeSession, redirectAfterRegistration} from "./session";
import {loginRegistrationAdditionalHeaders} from "./utils";
import {EMAIL_REG_EXP} from "./consts";
import {disableSubmitButton} from "../js/popups/activate_forms";
import {APP_SERVER_URL} from "../../../config";
import {internalSetCookie} from "./cookies";

export const login = (data) => {
    const loginSubmitButton = document.getElementById('login-form-submit-button');

    if (loginSubmitButton?.disabled === false) {
        disableSubmitButton(loginSubmitButton, true);
        const userNameEmail = data?.target[0]?.value;
        const password = data?.target[1]?.value;
        const remember = data?.target[3]?.checked;
        const affiliateInformation = getTrackingValues();
        const emailErrorSpan = document.getElementById('login-form-password-email-span');
        const passwordErrorSpan = document.getElementById('login-form-password-password-span');
        const errorMessageCredentials = document.getElementById('login-form-password-credentails-span');

        const requestData = userNameEmail.match(EMAIL_REG_EXP)
            ? {
                email: userNameEmail.trim(),
                password,
                remember,
                affiliateInformation
            }
            : {
                nickName: userNameEmail.trim(),
                password,
                remember,
                affiliateInformation
            };

        fetch(`${APP_SERVER_URL}player/crm/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                ...loginRegistrationAdditionalHeaders()
            },
            body: JSON.stringify(requestData)
        })
            .then((response) => {
                if (!response.ok) {
                    // If response is not ok, parse the response to get the error details
                    return response.json().then(error => {
                        return Promise.reject(error);
                    });
                }
                return response.json();
            })
            .then((response) => {
                initializeSession(response);
                localStorage.setItem(
                    'rememberUser',
                    requestData.remember ? 'remember' : 'not_remember'
                );
                localStorage.setItem('email', response?.email);
                localStorage.setItem('userId', response?.id);
                setValueToBrowser('product', response?.product?.toString());
                internalSetCookie('userHasAccount', 'true');
                redirectAfterRegistration();
            })
            .catch((e) => {
                const {message} = e;
                switch (message) {
                    case 'account.status.is':
                        alert('El estado de la cuenta es no está activo.');
                        break;
                    case 'empty.email.nickname':
                        if (emailErrorSpan) {
                            emailErrorSpan.innerHTML = 'Correo electrónico y nombre de usuario vacíos';
                        }
                        break;
                    case 'empty.password':
                        if (passwordErrorSpan) {
                            passwordErrorSpan.innerHTML = 'Correo electrónico y nombre de usuario vacíos';
                        }
                        break;
                    case 'incorrect.credentials':
                        if (errorMessageCredentials) {
                            errorMessageCredentials.innerHTML = 'El nombre de usuario/correo electrónico o la contraseña son incorrectos';
                        }
                        break;
                    default :
                        if (errorMessageCredentials) {
                            errorMessageCredentials.innerHTML = 'Hay problemas con su cuenta. Por favor contacte al soporte.';
                        }
                        break;
                }
            })
            .finally(() => {
                disableSubmitButton(loginSubmitButton, false);
            });
    }
}
