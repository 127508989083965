export const imagesKeys = {
    "header.logo": {
        "large": 'assets/images/logo_main.svg',
        "small": 'assets/images/logo_main_small.svg',
    },
    "header.app": {
        "ios": 'assets/images/ios.svg',
        "android": 'assets/images/android.svg',
    },
    "main.casino": {
        "large": 'assets/images/casino_desktop.webp',
        "small": 'assets/images/casino_mobile.webp',
    },
    "main.sport": {
        "large": 'assets/images/sport_desktop.webp',
        "small": 'assets/images/sport_mobile.webp',
    },
    "main.vip": {
        "large": 'assets/images/vip_desktop.webp',
        "small": 'assets/images/vip_mobile.webp',
    },
};
